<template>
    <el-dialog :title="title" :visible.sync="visibleSync" :close-on-click-modal="false" width="500px" append-to-body>
        <el-row :gutter="15">
            <el-form label-width="88px" ref="form" :model="form" :rules="rules" :size="$store.getters.size">
                <el-col :span="24">
                    <el-form-item label="分类名称" prop="title">
                        <el-input v-model="form.title" placeholder="路况上报分类名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="是否显示" prop="isShow">
                        <el-radio-group v-model="form.isShow">
                            <el-radio :label="item.value" v-for="(item, index) in isShowGroup" :key="index">{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="排序值" prop="sort">
                        <el-input-number v-model="form.sort" :min="1" :step="1"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="form.remark"
                                  placeholder="备注"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm" :loading="loading" :size="$store.getters.size">提交</el-button>
            <el-button @click="close" :size="$store.getters.size">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { saveTrafficType, editTrafficType, getTrafficType} from '@/api/delivery/traffic_type'

    export default {
        name: "SaveTrafficType",
        data(){
            return {
                visibleSync: false,
                title: undefined,
                isShowGroup: [{value: 1, label: '显示'}, {value: 2, label: '不显示'}],
                form: {},
                rules: {
                    title: []
                },
                loading: false
            }
        },
        methods: {
            /** 新增分类 */
            addTrafficType(){
                this.title = "新增路况上报分类";
                this.initFormData();
                this.visibleSync = true;
            },
            updateTrafficType(guid){
                this.title = "修改路况上报分类";
                this.initFormData();
                getTrafficType(guid).then(({code, data}) => {
                    if(code === 0){
                        this.form = data;
                        this.visibleSync = true;
                    }
                })
            },
            /** 提交表单 */
            submitForm(){
                this.$refs.form.validate((valid) => {
                    if(valid && this.loading === false){
                        this.loading = true;
                        if(this.form.guid){
                            editTrafficType(this.form).then(({code}) => {
                                this.loading = false
                                if(code === 0){
                                    this.$message.success("修改成功");
                                    this.close();
                                    this.$parent.$parent.getTableData();
                                }
                            })
                        }else{
                            saveTrafficType(this.form).then(({code}) => {
                                this.loading = false
                                if(code === 0){
                                    this.$message.success("添加成功");
                                    this.close();
                                    this.$parent.$parent.getTableData();
                                }
                            })
                        }
                    }
                })
            },
            /** 关闭弹窗 */
            close(){
                this.initFormData();
                this.visibleSync = false;
            },
            /** 初始表单 */
            initFormData(){
                this.form = {
                    guid: undefined,
                    title: undefined,
                    isShow: 1,
                    sort: 10,
                    remark: undefined
                };
            }
        }
    }
</script>

<style scoped>

</style>
