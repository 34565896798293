<template>
    <el-dialog top="7vh" :visible.sync="visibleSync" title="路况分类" width="60%" :close-on-click-modal="false">
        <div>
            <el-row :gutter="1.5" class="mb10">
                <el-col :span="24">
                    <el-button
                       type="primary"
                       :size="$store.getters.size"
                       v-if="$app.haveRole('traffic:type:add')"
                       @click="save()"
                       icon="el-icon-plus">添加</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" :size="$store.getters.size" height="510px"
                      @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="分类标题" prop="title"></el-table-column>
                <el-table-column label="排序值" prop="sort" width="100" align="center"></el-table-column>
                <el-table-column label="是否显示" width="100" align="center">
                    <template slot-scope="scope">
                        {{scope.row.isShow === 1 ? '是': '否'}}
                    </template>
                </el-table-column>
                <el-table-column label="创建日期" prop="createTime" width="160"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button v-if="$app.haveRole('traffic:type:update')" type="text" @click="edit(scope.row)">编辑</el-button>
                        <el-button v-if="$app.haveRole('traffic:type:remove')" type="text" @click="remove(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <save-traffic-type ref="saveTrafficType" />
    </el-dialog>
</template>

<script>
    import {getTypeList, removeTrafficType} from '@/api/delivery/traffic_type'
    import SaveTrafficType from './save_traffic_type'

    export default {
        name: "TrafficType",
        components: {SaveTrafficType},
        data(){
            return{
                visibleSync: false,
                tableData: [{guid: '112', title: '测试一', isShow: 2, createTime: '2021-08-07',sort: 10}],
                multipleSelection: []
            }
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            /** 获取所有数据 */
            getTableData(){
                getTypeList().then(({code, data}) => {
                    if(code === 0){
                        this.tableData = data;
                    }
                })
            },
            /** 新增分类 */
            save(){
                this.$refs['saveTrafficType'].addTrafficType();
            },
            /** 修改分类 */
            edit(row){
                this.$refs['saveTrafficType'].updateTrafficType(row.guid);
            },
            /** 删除 */
            remove(row){
                this.$confirm('你确定要删除此数据?','提示', {
                    type: "warning"
                }).then(() => {
                    removeTrafficType(row.guid).then(({code}) => {
                        if(code === 0){
                            this.getTableData();
                            this.$message.success("删除成功");
                        }
                    })
                }).catch(()=>{})

            },
            /** 多选 */
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
        }
    }
</script>

<style scoped>

</style>
