import request from '@/utils/request'

/** 获取路况上报分类 */
export function getAll() {
    return request({
        url: '/delivery/traffic/type/getAll',
        method: 'GET'
    })
}


/** 获取路况上报所有分类 */
export function getTypeList(){
    return request({
        url: '/delivery/traffic/type/list',
        method: 'GET'
    })
}

/** 保存路况上报分类 */
export function saveTrafficType(params) {
    return request({
        url: '/delivery/traffic/type/save',
        method: 'POST',
        data: params
    })
}

/** 删除路况上报分类 */
export function removeTrafficType(gid) {
    return request({
        url: '/delivery/traffic/type/remove/' + gid,
        method: 'DELETE'
    })
}

/** 修改路况上报分类 */
export function editTrafficType(params) {
    return request({
        url: '/delivery/traffic/type/save',
        method: 'PUT',
        data: params
    })
}

export function getTrafficType(guid) {
    return request({
        url: '/delivery/traffic/type/get/'+ guid,
        method: 'GET'
    })
}



