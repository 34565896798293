<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouse" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.typeId" placeholder="分类">
                    <el-option v-for="(item, index) in trafficTypeOption" :label="item.title" :value="item.guid" :key="index" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="1.5" class="mb20">
            <el-col :span="24">
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    :disabled="multipleSelection.length<=0"
                    v-if="$app.haveRole('traffic:road:deletes')"
                    :size="$store.getters.size"
                    @click="handleDeletes"
                >批量删除</el-button>

                <el-button
                    type="primary"
                    style="float: right;margin-right: 50px;"
                    :size="$store.getters.size"
                    @click="trafficTypeBtn()"
                    v-if="$app.haveRole('traffic:type:list')"
                >路况分类</el-button>
            </el-col>
        </el-row>

        <el-table :data="tableData" @selection-change="handleSelectionChange"
                  :size="$store.getters.size" v-loading="loading" ref="table"
                  row-key="guid">
            <el-table-column type="expand" width="1">
                <template slot-scope="scope">
                    <el-form label-width="100px" :size="$store.getters.size">
                        <el-form-item label="上报内容">{{scope.row.trafficContent}}</el-form-item>
                        <el-form-item label="上报时间">{{scope.row.createTime}}</el-form-item>
                        <el-form-item label="司机姓名">{{scope.row.driverName}}</el-form-item>
                        <el-form-item label="联系电话">{{scope.row.driverTel}}</el-form-item>
                        <el-form-item label="描述">{{scope.row.summary}}</el-form-item>
                        <el-form-item label="备注">{{scope.row.remark}}</el-form-item>
                        <el-form-item label="状态">
                            <el-tag v-if="scope.row.process === 1" type="warning" size="mini">待处理</el-tag>
                            <el-tag v-if="scope.row.process === 2" type="" size="mini">处理中</el-tag>
                            <el-tag v-if="scope.row.process === 3" type="success" size="mini">已处理</el-tag>
                        </el-form-item>
                        <el-form-item label="处理人">{{scope.row.processName}}</el-form-item>
                        <el-form-item label="开始处理时间">{{scope.row.processStartTime}}</el-form-item>
                        <el-form-item label="处理结束时间">{{scope.row.processEndTime}}</el-form-item>
                        <el-form-item label="处理内容" v-if="scope.row.process === 3"> {{scope.row.processContent}}</el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="上报内容" prop="trafficContent"></el-table-column>
            <el-table-column label="司机姓名" prop="driverName" width="150"></el-table-column>
            <el-table-column label="司机电话" prop="driverTel" width="180"></el-table-column>
            <el-table-column label="上报时间" prop="createTime" width="200"></el-table-column>
            <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.process === 1" type="warning" size="mini">待处理</el-tag>
                    <el-tag v-if="scope.row.process === 2" type="" size="mini">处理中</el-tag>
                    <el-tag v-if="scope.row.process === 3" type="success" size="mini">已处理</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="处理人" prop="processName" width="150"></el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        v-if="scope.row.process === 1 && $app.haveRole('traffic:road:process')"
                        @click="goProcess(scope.row)">去处理</el-button>
                    <el-button
                        type="text"
                        v-if="btnShow(scope.row)"
                        @click="endProcess(scope.row)">已处理</el-button>
                    <el-button
                        type="text"
                        v-if="$app.haveRole('traffic:road:delete')"
                        @click="handleDelete(scope.row)">删除</el-button>
                    <el-button type="text" @click="toogleExpand(scope.row)">{{scope.row.expansion ? '收起' : '查看详情'}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>
        <traffic-type ref="trafficType" v-if="$app.haveRole('traffic:type:list')"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import TrafficType from './traffic_type'
    import {getAll} from '@/api/delivery/traffic_type'
    import {getList, processing, processed, remove, deletes} from '@/api/delivery/traffic_road'

    export default {
        name: "DeliveryTraffic",
        components: {PageItem, TrafficType},
        data(){
            return {
                queryParams: {
                    title: undefined,
                    warehouse: undefined,
                    typeId: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                multipleSelection: [],
                tableData: [],
                loading: false,
                total: 0,
                trafficTypeOption: [],
            }
        },
        mounted() {
            this.getTrafficType();
            if(this.$store.getters.warehouse.length === 1){
                this.queryParams.warehouse = this.$store.getters.warehouse[0].value
                this.getTableData();
            }
        },
        methods: {
            getTableData(){
                if(!this.queryParams.warehouse){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getList(this.queryParams).then(({code, data}) => {
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 搜索 */
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            /** 多选 */
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            /** 批量删除 */
            handleDeletes(){
                if(this.multipleSelection.length<=0){
                    this.$message.error("未选择需要删除数据!");
                    return false;
                }
                this.$confirm("你确定要删除选中的数据?","提示",{
                    type: "warning"
                }).then(() => {
                    const ids = this.multipleSelection.map(e => e.guid)
                    deletes(ids).then(({code}) => {
                        if(code === 0){
                            this.$message.success("批量删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=> {})
            },
            /** 刪除 */
            handleDelete(row){
                this.$confirm('你确定要删除此数据?', '提示', {
                    type: "warning"
                }).then(() => {
                    remove(row.guid).then(({code}) => {
                        if(code === 0){
                            this.$message.success("删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 获取路况分类 */
            getTrafficType() {
                getAll().then(({code, data}) => {
                    if(code === 0){
                        this.trafficTypeOption = data;
                    }
                })
            },
            /** 去处理 */
            goProcess(row){
                this.$confirm("你确定要去处理吗?","提示", {
                    type: "warning"
                }).then(() => {
                    processing(row.guid).then(({code}) => {
                        if(code === 0){
                            this.$message.success("成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 已处理 */
            endProcess(row){
                this.$prompt('', '处理内容', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'textarea',
                    closeOnClickModal: false,
                    showInput: true,
                    inputPlaceholder: '请输入处理内容',
                    inputValidator: (value) => {
                        if(!value){
                            return "处理内容不能为空"
                        }
                    }
                }).then(({value}) => {
                    const data = { guid: row.guid, content: value}
                    processed(data).then(({code}) => {
                        if(code === 0){
                            this.$message.success("成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})

            },
            /** 判断处理完成按钮是否显示 */
            btnShow(row){
                if(this.$app.haveRole("traffic:road:process") === false){
                    return false;
                }
                const userInfo = this.$store.getters.userInfo;
                if(row.process === 2 && userInfo){
                    if(userInfo.guid == row.processBy){
                        return true;
                    }
                }
                return false;
            },
            /** 路况分类按钮 */
            trafficTypeBtn(){
                this.$refs['trafficType'].visibleSync = true;
            },
            toogleExpand(row){
                let $table = this.$refs.table;
                this.tableData.map(item => {
                    if (row.guid != item.guid) {
                        $table.toggleRowExpansion(item, false)
                        item.expansion = false
                    } else {
                        item.expansion = !item.expansion
                    }
                })
                $table.toggleRowExpansion(row)
            }
        }
    }
</script>

<style scoped>

</style>
